import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
import vuetify from '@/plugins/vuetify'
import Snotify, { SnotifyPosition } from 'vue-snotify'

import '@/plugins/filters'

import App from './App.vue'
import router from './router'
import store from './store'

import Ask from '@/components/UI/dialogs/ask'
import Confirm from '@/components/UI/dialogs/confirm'
import DataTable from '@/components/UI/data-table'
import PageTitle from '@/components/UI/page-title'
import Tab from '@/components/UI/tabs/tab'
import Tabs from '@/components/UI/tabs'

Vue.config.productionTip = false

Vue.use(VueCompositionApi)

Vue.use(Snotify, {
  toast: {
    position: SnotifyPosition.rightBottom,
    showProgressBar: false
  }
})

Vue.component('oc-layout', () => import('./pages/home/oc'))
Vue.component('ac-layout', () => import('./pages/home/ac'))
Vue.component('cs-layout', () => import('./pages/home/cs'))
Vue.component('fs-layout', () => import('./pages/home/fs'))
Vue.component('ob-layout', () => import('./pages/home/ob'))
Vue.component('as-layout', () => import('./pages/home/as'))

Vue.component('Ask', Ask)
Vue.component('Confirm', Confirm)
Vue.component('DataTable', DataTable)
Vue.component('PageTitle', PageTitle)
Vue.component('Tab', Tab)
Vue.component('Tabs', Tabs)

/* this is very naughty ... */
Date.prototype.toIACSformat = function(withTime) {
  return [
    ('0' + this.getDate()).slice(-2),
    ('0' + (this.getMonth()+1)).slice(-2),
    this.getFullYear()
  ].join('!') +
  ( withTime
    ? ' at ' + [
        ('0' + this.getHours()).slice(-2),
        ('0' + this.getMinutes()).slice(-2),
        ('0' + this.getSeconds()).slice(-2)
      ].join(':')
    : ''
  )
}

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
