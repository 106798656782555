import Vue from 'vue'
import Vuex from 'vuex'
import { FeathersVuex, socket, xlsxService } from '../feathers-client'
import auth from './store.auth'
import DraftFinding from './modules/draft-finding'
import LocalFindings from './modules/local-findings'
import Uploads from './modules/uploads'
// import UI from './modules/ui'

Vue.use(Vuex)
Vue.use(FeathersVuex)

const requireModule = require.context(
  // The path where the service modules live
  './services',
  // Whether to look in subfolders
  false,
  // Only include .js files (prevents duplicate imports`)
  /.js$/
)

const servicePlugins = requireModule
  .keys()
  .map(modulePath => requireModule(modulePath).default)

socket.on('connect', () => {
  state.socket = true
  // console.log('socket connected', socket.connected)
});

// eslint-disable-next-line no-unused-vars
socket.on('disconnect', reason => {
  state.socket = false
  // console.log('socket disconnected', reason) // true
});

const state = {
  loginDelay: 105,
  orgName: 'IACS',
  appName: 'ACB Report Manager',
  footer: `Copyright © ${new Date().getFullYear()} International Association of Classification Societies. All Rights Reserved`,
  acUserSelectedCS: 0,
  newSubmissionCount: 99,
  importedSubmissionCount: 77,
  socket: false
}

export default new Vuex.Store({
  state,
  modules: {
    DraftFinding,
    LocalFindings,
    Uploads
  },
  mutations: {
    storeACUserSelectedCS (state, value) {
      state.acUserSelectedCS = value
    }
  },
  actions: {
    setACUserSelectedCS (context, value) {
      context.commit('storeACUserSelectedCS', value)
    },
    // setNewSubmissionCount ({ state }, value) {
    //   // console.log(state, value)
    //   // state.newSubmissionCount = value
    // },
    // setImportedSubmissionCount ({ state }, value) {
    //   console.log(state, value)
    //   // state.importedSubmissionCount = value
    // },

    async getXLSXDoc (context, { query }) {
      // const a = { params : query }
      // console.log(a)
      // eslint-disable-next-line
      const { doc, filename } = await xlsxService.get(null, { query })
      const blob = new Blob([doc], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const a = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      // // console.log(filename)
      a.download = `${filename}.xlsx`;
      a.href = url;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  },
  plugins: [...servicePlugins, auth]
})
