<template>
  <th
    nowrap
    :class="{
      'px-0': compact,
      'px-2': !compact,
      'primary--text': true,
      'h-double-rule': true
    }"
    _style="background-color:#ddd"
    @click="showMenu = true"
  >
    <v-menu
      v-model="showMenu"
      offset-y
      offset-overflow
      content-class="elevation-12"
      max-height="65vh"
      tile
      nudge-left="9"
      nudge-bottom="3"
    >
      <template v-slot:activator="{ attrs }">
        <div
          v-bind="attrs"
          class="d-flex justify-space-between"
        >
          <div class="d-flex align-center">
            <span>{{ label }}</span>
            <v-icon
              v-if="lsort===null"
              small
              style="visibility:hidden"
              class="pl-1"
            >
              mdi-minus
            </v-icon>

            <v-icon
              v-if="lsort==='-1'"
              small
              class="pl-1"
            >
              mdi-arrow-down
            </v-icon>

            <v-icon
              v-if="lsort==='1'"
              small
              class="pl-1"
            >
              mdi-arrow-up
            </v-icon>

            <v-icon
              v-if="lselected!==undefined"
              small
            >
              mdi-filter-outline
            </v-icon>

            <v-icon
              v-if="lselected===undefined"
              small
              style="visibility:hidden"
            >
              mdi-filter-outline
            </v-icon>
          </div>
          <v-icon class="ml-n2">mdi-menu-down</v-icon>
        </div>
      </template>

      <v-list
        dense
        min-width="240"
        outlined
      >
        <v-list-item-group
          v-model="lsort"
        >
          <v-list-item
            value="1"
          >
            <v-list-item-icon>
              <v-icon>
                mdi-sort-alphabetical-ascending
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              A on Top
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            value="-1"
          >
            <v-list-item-icon>
              <v-icon class="ma-0">
                mdi-sort-alphabetical-descending
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              Z on Top
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>

        <v-divider
          class="my-2"
        />

        <v-list-item
          key="CLEAR"
          :disabled="lselected===undefined"
          @click="lselected=undefined"
        >
          <v-list-item-icon>
            <v-icon
              :disabled="lselected===undefined"
              color="error"
            >
              mdi-close
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            Clear Filter
          </v-list-item-title>
        </v-list-item>

        <v-divider class="my-2" />

        <v-list-item-group
          v-model="lselected"
        >
          <v-list-item
            v-for="(item, i) in menuItems"
            :key="i"
            :value="item.value || item"
          >
            <v-list-item-icon>
              <v-icon
                v-if="lselected==(item.value||item)"
                color="success"
              >
                mdi-check
              </v-icon>
              <v-icon v-else/>
            </v-list-item-icon>
            <v-list-item-title>
              {{ item.label || item }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </th>
</template>

<style lang="css" scoped>
  .v-list-item--dense, .v-list--dense .v-list-item{
    min-height: 30px
  }
  .v-list-item--dense .v-list-item__icon, .v-list--dense .v-list-item .v-list-item__icon{
    margin-top: 3px;
    margin-bottom: 3px;
    margin-right:16px;
    margin-left:-4px;
  }
  .pointy{
    cursor: pointer;
  }
  table thead tr th,
  table tbody tr td {
    padding: 0px;
  }
</style>

<script>
export default {
  props: {
    compact: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'no label'
    },
    field: {
      type: String,
      default: 'no-field'
    },
    selected: {
      type: String,
      default: undefined
    },
    items: {
      type: Array,
      default () { return [] }
    },
    sort: {
      type: String,
      default: null
    }
  },
  data: () => {
    return {
      showMenu: false
    }
  },
  computed: {
    menuItems () {
      // return this.$store.dispatch('search-lists/get', 'acbPR')
      // return this.$store.dispatch('search-lists/get', 'acbPR').then(() => {
      //   // console.log(result)
      //   // return result
      // })
      return []

      // get items from the store
      // const items = this.$store.state.reportParameters.reportParameters[this.field] || []
      // // filter out any dividers
      // return items.filter(t => !t.divider)
    },
    lselected: {
      get () {
        return this.selected
      },
      set (selected) {
        this.$emit('filter', {
          field: this.field,
          value: selected
        })
      }
    },
    lsort: {
      get () {
        return this.sort
      },
      set (value) {
        this.$emit('sort', { value, field: this.field })
      }
    }
  }
}
</script>
