import Vue from 'vue'
import idb from '@/api/index-db'

const state = () => ({
  uploads: []
})

const actions = {
  async fetch({ state }) {
    // console.log('FETCH')
    let uploads = await idb.getUploads()
    state.uploads = []
    uploads.forEach(c => {
      state.uploads.push(c)
    })
  },
  async queue({ dispatch }, upload) {
    await idb.saveUpload(upload)
    await dispatch('fetch')
  },
  async unqueue({ dispatch }, upload) {
    await idb.deleteUpload(upload)
    await dispatch('fetch')
  },
  async upload({ dispatch, rootState }, upload) {

    // if online, send
    // should really not be reachable if
    // offline but nice to do a check here.

    // console.log('>>>>>>>>>>> UPLOAD')

    if (!rootState.socket) {
      return alert('you are offline')
    }

    const { Submission } = Vue.$FeathersVuex.api

    const metadata = {
      uploaded: {
        date: Date.now(),
        browser: {
          platform: navigator.platform,
          language: navigator.language,
          userAgent: navigator.userAgent
        }
      }
    }

    const sub = new Submission({ ...upload, metadata, id: null })

    try {
      await sub.save()

      upload.metadata = metadata
      await idb.saveUpload(upload)

    } catch (error) {
      alert(error)
      // console.log(error)
    }
    // update the vuex store
    await dispatch('fetch')
  },
}

const getters = {
  queued: state => {
    return state.uploads.filter(u => !u.metadata)
  },
  uploaded: state => {
    return state.uploads.filter(u => u.metadata ).sort((a, b) => a.id < b.id ? 1 : -1)
  }
}

export default {
  namespaced: true,
  actions,
  getters,
  state
}
