import feathersClient, {
  makeServicePlugin,
  BaseModel
} from '../../feathers-client'

class User extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'User'
  // Define default properties here
  static instanceDefaults() {
    return {
      assignedTo: [],
      canCloseFindings: false,
      cs: {},
      email: '',
      enabled: false,
      fs: {},
      name: '',
      password: '',
      role: '',
      type: ''
    }
  }

  static setupInstance(data) {
    // console.log(data)
    // data.uploads.forEach(u => {
    //   u.acbDate = new Date(u.acbDate)
    // })
    return data
  }
}
const servicePath = 'users'
const servicePlugin = makeServicePlugin({
  Model: User,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [
      context => {
        if (context.data.type !== 'cs') {
          delete context.data.cs
        }
        if (context.data.type !== 'fs') {
          delete context.data.fs
        }
      }
    ],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
