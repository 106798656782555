<template>
  <v-form
    v-model="valid"
    ref="loginForm"
    @submit.prevent="tryLogin(email, password)"
  >
   <!--  <v-select
      v-model="email"
      :items="dummyLogins"
      class="mb-4"
      dense
      flat
      hide-details
      label="email"
      solo
      :ripple="false"
      :rules="emailRules"
      @change="password = email"
    ></v-select> -->

    <v-text-field
      v-model="email"
      autocomplete="off"
      class="mb-4"
      dense
      flat
      hide-details
      label="Email"
      required
      solo
      type="email"
      :rules="emailRules"
    />

    <v-text-field
      v-model="password"
      autocomplete="off"
      dense
      flat
      hide-details
      label="Password"
      min="8"
      required
      solo
      type="password"
      :rules="passwordRules"
    />

    <v-alert
      v-if="error"
      class="warning mb-n3 mt-5"
      dismissible
    >
      {{ error.message }}
    </v-alert>

    <v-layout justify-center>
      <v-btn
        class="mt-10 mb-4"
        color="white"
        depressed
        type="submit"
        x-large
        :loading="waiting"
        :dark="!valid"
        :disabled="!valid"
        :ripple="false"
      >
        Login
      </v-btn>
    </v-layout>
    <div class="help font-weight-black pa-2" @click="$emit('change')">
      <span class="pr-1">Request Password Reset</span>
      <v-icon color="white">
        mdi-account-circle
      </v-icon>
    </div>
  </v-form>
</template>

<style scoped>
  .help{
    align-items: center;
    bottom:0;
    color:white;
    cursor: pointer;
    display: flex;
    font-size: 0.75rem;
    justify-content: flex-end;
    opacity: 0.35;
    overflow-x: hidden;
    position: absolute;
    right:0;
    text-transform: uppercase;
    transition: width .15s ease;
    white-space: nowrap;
    width: 40px;
  }
  .help span{ opacity: 0; }
  .help:hover{ opacity: 0.95; width: 205px; }
  .help:hover span{ opacity: 0.95; }
</style>

<script>
import { ref } from '@vue/composition-api'

export default {
  name: 'LoginForm',
  setup(props, context) {
    const { $store } = context.root

    const email = ref('')
    const emailRules = ref([
      v => (!!v || 'Email is required'),
      v => (/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(v)) || 'Not a valid email'
    ])
    const error = ref(null)
    const password = ref('')
    const passwordRules = ref([v => (!!v || 'Password is required')])
    const valid = ref(false)
    const waiting = ref(false)

    const dummyLogins = ref([
      'peterwilliams@iacs.org.uk',
      'jos.koster@dekra.com',
      'cs@iacs.org',
      'rina@cs.org'
    ])

    const tryLogin = (email, password) => {
      waiting.value = true
      error.value = null
      $store
        .dispatch('auth/authenticate', { strategy: 'local', email, password }).then(() => {
          waiting.value = false
        })
        .catch(err => {
          let type = err.className
          err = Object.assign({}, err)
          err.message =
            type === 'not-authenticated'
              ? 'Incorrect email or password.'
              : 'An error prevented login.'
          error.value = err
          waiting.value = false
        })
    }

    return {
      dummyLogins,
      email,
      emailRules,
      error,
      password,
      passwordRules,
      tryLogin,
      valid,
      waiting
    }
  }
}
</script>
