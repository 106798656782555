import feathers from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import auth from '@feathersjs/authentication-client'
import io from 'socket.io-client'
// import { iff, discard } from 'feathers-hooks-common'
import feathersVuex from 'feathers-vuex'


const API_URL = process.env.VUE_APP_API_URL

const socket = io(API_URL, {
  timeout: 20000,
  transports: ['websocket']
})

const feathersClient = feathers()
  .configure(socketio(socket))
  .configure(auth({ storage: window.localStorage }))
  .hooks({
    before: {
      all: [
        // iff(
        //   context => ['create', 'update', 'patch'].includes(context.method),
        //   discard('__id', '__isTemp')
        // )
      ]
    }
  })

const xlsxService = feathersClient.service('xlsx')
// xlsxService.on('find', () => {
//   console.log('find')
// })

export default feathersClient

// Setting up feathers-vuex
const {
  makeServicePlugin,
  makeAuthPlugin,
  BaseModel,
  models,
  FeathersVuex
} = feathersVuex(feathersClient, {
  debug: true,
  serverAlias: 'api',
  idField: '_id', // Must match the id field in your database table/collection
  whitelist: ['$regex', '$options', '$and', '$or'],
  paramsForServer: ['$populate']
})

export { makeAuthPlugin, makeServicePlugin, BaseModel, models, FeathersVuex, socket, xlsxService }
