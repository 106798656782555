const DB_NAME = 'iacs-arm-auditor-local-findings'
const DB_VERSION = 1
let DB

export default {

  async getDb () {
    return new Promise((resolve, reject) => {
      if(DB) { return resolve(DB) }
      // console.log('OPENING DB', DB_NAME)
      let request = window.indexedDB.open(DB_NAME, DB_VERSION)
      request.onerror = () => {
        // console.log('Error opening db', e)
        reject('Error')
      }
      request.onsuccess = e => {
        DB = e.target.result
        resolve(DB)
      }
      request.onupgradeneeded = e => {
        // console.log('onupgradeneeded')
        let db = e.target.result
        db.createObjectStore('findings', { autoIncrement: true, keyPath:'id' })
        db.createObjectStore('uploads', { autoIncrement: true, keyPath:'id' })
      }
    })
  },

  async getFindings () {
    let db = await this.getDb()
    return new Promise(resolve => {
      let trans = db.transaction(['findings'],'readonly')
      trans.oncomplete = () => {
        resolve(findings)
      }
      let store = trans.objectStore('findings')
      let findings = []
      store.openCursor().onsuccess = e => {
        let cursor = e.target.result
        if (cursor) {
          findings.push(cursor.value)
          cursor.continue()
        }
      }
    })
  },
  async deleteFinding (finding) {
    let db = await this.getDb()
    return new Promise(resolve => {
      let trans = db.transaction(['findings'],'readwrite')
      trans.oncomplete = () => {
        resolve()
      }
      let store = trans.objectStore('findings')
      store.delete(finding.id)
    })
  },
  async saveFinding (finding) {
    let db = await this.getDb()
    return new Promise((resolve, reject) => {
      let trans = db.transaction(['findings'],'readwrite')
      trans.oncomplete = () => {
        // request.result contains the id of the record
        // that was inserted or updated
        resolve(request.result)
      }
      trans.onerror = () => {
        // console.log('Error saving', e)
        reject('Error')
      }
      let store = trans.objectStore('findings')
      const request = store.put(finding)
    })
  },

  async getUploads () {
    let db = await this.getDb()
    return new Promise(resolve => {
      let trans = db.transaction(['uploads'],'readonly')
      trans.oncomplete = () => {
        resolve(uploads)
      }
      let store = trans.objectStore('uploads')
      let uploads = []
      store.openCursor().onsuccess = e => {
        let cursor = e.target.result
        if (cursor) {
          uploads.push(cursor.value)
          cursor.continue()
        }
      }
    })
  },
  async saveUpload (upload) {
    let db = await this.getDb()
    return new Promise((resolve, reject) => {
      let trans = db.transaction(['uploads'],'readwrite')
      trans.oncomplete = () => {
        // request.result contains the id of the record
        // that was inserted or updated
        resolve(request.result)
      }
      trans.onerror = () => {
        // console.log('Error saving', e)
        reject('Error')
      }
      let store = trans.objectStore('uploads')
      const request = store.put(upload)
    })
  },
  async deleteUpload (upload) {
    let db = await this.getDb()
    return new Promise(resolve => {
      let trans = db.transaction(['uploads'],'readwrite')
      trans.oncomplete = () => {
        resolve()
      }
      let store = trans.objectStore('uploads')
      store.delete(upload.id)
    })
  }

}
