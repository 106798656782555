import feathersClient, {
  makeServicePlugin,
  BaseModel
} from '../../feathers-client'

class ClassSociety extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'ClassSociety'
  // Define default properties here
  static instanceDefaults() {
    return {
      name: '',
      enabled: false,
      acb: {}
    }
  }
  // static setupInstance(data) {
  //   if (data.createdAt) {
  //     data.createdAt = new Date(data.createdAt)
  //   }
  //   return data
  // }
}
const servicePath = 'class-societies'
const servicePlugin = makeServicePlugin({
  Model: ClassSociety,
  service: feathersClient.service(servicePath),
  servicePath,
  debug: true
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
