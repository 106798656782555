<template>
  <div class="iacs-data-table">
    <v-simple-table
      dense
      fixed-header
    >
      <template v-slot:default>
        <colgroup>
          <col v-if="expandable" span="1" style="width: 1%">
          <col v-if="selectable" span="1" style="width: 1%">
          <col
            v-for="(col, i) in cols"
            span="1"
            :key="i"
            :style="`width: ${col.width}`"
          >
        </colgroup>
        <thead>
          <tr>
            <th
              v-if="expandable"
              :class="{
                'px-0': true,
                'h-double-rule': true
              }"
              @click="expandAll"
            >
              <v-icon class="pl-2 pr-0" v-if="Boolean(expanded.length === data.length)"
                >mdi-chevron-up</v-icon>
              <v-icon class="pl-2 pr-0" v-else
                >mdi-chevron-down</v-icon>
            </th>
            <th
              v-if="selectable"
              class="px-0 h-double-rule"
            >
              <Checkbox
                :indeterminate="Boolean(selected.length && selected.length < selectableItems.length)"
                :value="Boolean(selected.length && (selected.length === selectableItems.length))"
                @change="selectAll"
              />
            </th>
            <template
              v-for="(col, i) in cols"
            >
              <SortableHeader
                v-if="col.filter"
                :key="i"
                :compact="col.compact"
                :field="col.field"
                :label="col.label"
                :sort="currentSort[col.field]"
                :selected="currentFilters[col.field]"
                @sort="setSort"
                @filter="setFilter"
              />
              <th
                v-else
                nowrap
                style="min-height:35px;height:35px"
                :class="
                  {
                    'px-0': col.compact,
                    'px-2': !col.compact,
                    'text-left': true,
                    'text-right': col.align==='right',
                    'text-center': col.align==='center',
                    'primary--text': true,
                    'v-rule': col.divider,
                    'h-double-rule': true
                  }"
                _style="background-color:#e2f0f8"
                :key="i"
              >
                {{ col.label }}
              </th>
            </template>
          </tr>
        </thead>
        <tbody
          v-if="data.length"
        >
          <template
            v-for="(item, i) in data"
          >
            <tr
              :key="i"
              :class="{ selected: isSelected(item), expanded: isExpanded(item) }"
              @click="expandable ? expand(item) : $emit('selectOne', item)"
            >
              <td
                v-if="expandable"
                class="pl-2 pr-0"
              >
                <v-icon>
                  mdi-chevron-right {{ isExpanded(item) ? 'mdi-rotate-90' : '' }}
                </v-icon>
              </td>
              <td
                v-if="selectable"
                class="px-0"
              >
                <Checkbox
                  :disabled="!canSelect(item)"
                  :value="isSelected(item)"
                  @change="select(item)"
                />
              </td>
              <td
                v-for="(col, j) in cols"
                :class="{
                  'px-0': col.compact,
                  'px-2': !col.compact,
                  'text-left': true,
                  'text-right': col.align==='right',
                  'text-center': col.align==='center',
                  truncate: col.truncate,
                  nowrap: !col.wrapText,
                  textAlign: col.textAlign,
                  'v-rule': col.divider
                 }"
                :key="j"
                :style="`cursor:pointer;background-color:${rowColor(item)}`"
                :title="col.truncate ? val(item, col.field, i, j) : false"
              >
                <template
                  v-if="!(expandable && isExpanded(item) && col.hideOnExpand)"
                >
                  <slot :name="`item.${col.field}`" :item="item">
                    <template v-if="col.type===Boolean">
                      <v-icon
                        dense
                        :color="getIcon(item, col).color"
                      >
                        {{ getIcon(item, col).icon }}
                      </v-icon>
                    </template>
                    <template v-else>
                      {{ col.valueFunction ? col.valueFunction(item, col.field, i, j) : val(item, col.field, i, j) }}
                    </template>
                  </slot>
                </template>
              </td>
            </tr>
            <tr
              v-if="expandable && isExpanded(item)"
              :key="`expander${i}`"
              class="expander"
            >
              <td
                :colspan="cols.length + (selectable ? 2 : 1)"
                class="px-0"
              >
                <slot name="item.expander" :item="item" />
              </td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
        </tbody>
      </template>
    </v-simple-table>
    <div
      v-if="!data.length"
      class="text-h4 px-2 py-6 text--disabled text--lighten-5"
      _style="min-height:320px"
    >
      No Data
    </div>
  </div>
</template>

<style>
  .v-data-table--fixed-header > .v-data-table__wrapper > table > thead > tr > th.h-double-rule{
    border-top-style: none !important;
    border-bottom-width: 2px !important;
    border-bottom-style: solid !important;
    border-bottom-color: rgba(0, 0, 0, 0.12) !important;
  }
  th.v-rule:not(:last-child){
    border-right: thin solid rgba(0, 0, 0, 0.12) !important;
  }
  table > tbody > tr > td.v-rule:not(:last-child){
    border-right: thin solid rgba(0, 0, 0, 0.12);
  }
  table > tbody > tr.expanded > td{
    border-bottom-color: transparent !important;
    background-color: rgba(0, 0, 0, 0.06125) !important;
  }
  table > tbody > tr.expanded:hover{
    background-color: transparent !important;
  }
  table > tbody > tr.selected{
    background-color: rgba(0, 128, 255, 0.125);
  }
  table > tbody > tr > td{
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .nowrap{
    white-space: nowrap;
  }
  .truncate {
    max-width: 120px;
  }
  .expander {
    background-color: rgba(0, 0, 0, 0.06125) !important;
  }
</style>

<script>
import Checkbox from '@/components/UI/forms/checkbox'
import SortableHeader from './headers/Sortable'

export default {
  props: {
    canSelect: {
      type: Function,
      default () { return {} }
    },
    cols: {
      type: Array,
      default () { return [] }
    },
    data: {
      type: Array,
      default () { return [] }
    },
    expandable: {
      type: Boolean,
      default: false
    },
    rowColorResolver: {
      type: Function,
      default () { return {} }
    },
    selectable: {
      type: Boolean,
      default: false
    }
  },
  components: { Checkbox, SortableHeader },
  data () {
    return {
      expanded: [],
      selected: [],
      currentFilters: {},
      currentSort: {},
      // savedSearch: {
      //   sorts: [],
      //   filters: []
      // }
    }
  },
  computed: {
    rowColor () {
      return item => (
        this.rowColorResolver(item)
      )
    },
    val () {
      return (item, field) => {
        return item[field]
      }
    },
    selectableItems () {
      return this.data.filter(i => this.canSelect(i))
    }
  },
  methods: {
    isExpanded (item) {
      return this.expanded.findIndex(s => (s.id || s._id) === (item.id || item._id)) !== -1
    },
    isSelected (item) {
      return this.selected.findIndex(s => (s.id || s._id) === (item.id || item._id)) !== -1
    },
    expand (item) {
      const index = this.expanded.findIndex(s => (s.id || s._id) === (item.id || item._id))
      if ( index === -1){
        this.expanded.push(item)
      } else {
        this.expanded.splice(index, 1)
      }
      // this.$emit('expand', this.expanded)
    },
    expandAll () {
      if (this.expanded.length === this.data.length) {
        // select none
        this.expanded = []
      }else{
        // select all
        this.expanded = [...this.data]
      }
    },
    select (item) {
      const index = this.selected.findIndex(s => s.id === item.id)
      if ( index === -1){
        this.selected.push(item)
      } else {
        this.selected.splice(index, 1)
      }
      this.$emit('select', this.selected)
    },
    selectAll () {
      if (this.selected.length === this.selectableItems.length) {
        // select none
        this.selected = []
      }else{
        // select all
        this.selected = this.selectableItems
      }
      this.$emit('select', this.selected)
    },
    setSort (value) {
      const tmp = {...this.currentSort, [value.field]:value.value}
      // clear out any redundant (undefined) keys
      // if we dont do this then the sort order fails
      Object.keys(tmp).forEach(key => tmp[key] === undefined && delete tmp[key])
      this.currentSort = tmp
      this.$emit('sort', this.currentSort)
      return null
    },
    setFilter () {
      return null
    },
    getIcon (item, col) {
      const sbool = Boolean(item[col.field]) + ''
      return col.icons[sbool] || {}
    }
  },
  watch: {
    // recalulate "selected" when the data changes
    // we may have lost a record or two
    data (newVal) {
      //console.log(newVal)
      const selected = this.selected.filter(s => {
        const found = newVal.find(d => d.id === s.id)
        return found
      })
      this.selected = selected
    }
  }
}
</script>
