import idb from '@/api/index-db'

const state = () => ({
  findings: []
})

const actions = {

  async deleteFinding({ dispatch }, finding) {
    await idb.deleteFinding(finding)
    await dispatch('fetch')
    dispatch('DraftFinding/setFinding', null, {root:true})
  },
  async fetch({ state }) {
    let findings = await idb.getFindings()
    state.findings = []
    findings.forEach(c => {
      state.findings.push(c)
    })
    // console.log(state.findings)
  },
  async saveFinding({ dispatch, state }, finding) {
    const recordId = await idb.saveFinding(finding)
    await dispatch('fetch')
    // return ID to page
    if (!finding.id) {
      const saved = state.findings.find(f => f.id === recordId)
      dispatch('DraftFinding/setFinding', saved, {root:true})
    }
  },
  async queueFinding({ dispatch }, finding) {
    finding.queued = true
    await dispatch('saveFinding', finding)
  },
  async unqueueFinding({ dispatch }, finding) {
    finding.queued = false
    await dispatch('saveFinding', finding)
  },
}

const getters = {
  findings: state => {
    return state.findings
  }
}

export default {
  namespaced: true,
  actions,
  getters,
  state
}
