const nc = 'Non-Conformity'
const ob = 'Observation'

exports.fields = [
  {
    enabled: true,
    column: 0,  // this is on it's own panel
    field: 'acbFinding_Text',
    required: true
  },
  {
    enabled: true,
    column: 1,
    field: 'acbDate',
    marginTop: 3,
    required: true,
    type: 'date'
  },
  {
    // default selected value is Class Society currently applied to user
    enabled: false,
    column: 1,
    field: 'acbClass',
    marginTop: 1,
    required: true,
    type: 'select'
  },
  {
    enabled: false,
    column: 1,
    field: 'acbAuditor',
    required: true,
    type: 'select'
  },
  {
    enabled: false,
    column: 1,
    field: 'acbACB',
    required: true,
    type: 'select'
  },
  {
    enabled: true,
    column: 1,
    field: 'acbRemote',
    marginTop: 4,
    type: 'checkbox'
  },
  {
    enabled: true,
    column: 1,
    field: 'acbLocation',
    marginTop: 1,
    placeholder: 'City of Audit',
    required: true,
    type: 'input'
  },
  {
    enabled: [nc, ob],
    column: 1,
    field: 'acbDepartment',
    required: [nc, ob],
    placeholder: 'Location / Department / Process Audited',
    type: 'input'
  },
  {
    // disabled if a value in the Technical menu is selected
    enabled: [nc, ob],
    column: 1,
    field: 'acbSystem',
    marginTop: 5,
    required: [nc, ob],
    type: 'select'
  },
  {
    // disabled if a value in the System Related menu is selected
    enabled: [nc, ob],
    column: 1,
    field: 'acbTechnical',
    required: [nc, ob],
    type: 'select'
  },
  {
    enabled: [nc, ob],
    column: 1,
    field: 'acbFinding_Cat',
    required: [nc, ob],
    type: 'select'
  },
  {
    enabled: true,
    column: 2,
    field: 'acbAudit',
    marginTop: 3,
    required: true,
    type: 'input'
  },
  {
    enabled: true,
    column: 2,
    field: 'acbObs',
    required: true,
    type: 'input'
  },
  {
    enabled: true,
    column: 2,
    field: 'acbOffNo',
    required: false,
    type: 'input'
  },
  {
    enabled: true,
    column: 2,
    field: 'acbFlag',
    required: false,
    type: 'select'
  },
  {
    enabled: true,
    column: 2,
    field: 'acbAuditType',
    marginTop: 5,
    required: true,
    type: 'select'
  },
  {
    // shown only if Audit Type value selected is VCA
    // value list available in IACS ARM Value Lists.xlsx
    // required - but only if the Audit Type value selected is VCA
    column: 2,
    field: 'acbVCAType',
    // make these functions?
    // required: ,
    // disabled:true,
    type: 'select'
  },
  {
    enabled: [nc, ob],
    column: 2,
    field: 'acbIQMSR',
    marginTop: 5,
    required: [nc, ob],
    type: 'select'
  },
  {
    // automatically filled with values determined by which
    // IQMSR Para. No. value was selected
    // disabled - i.e. visible but not editable
    // required, but has legal null values >.<
    enabled: false,
    column: 2,
    field: 'acbRO_Code',
    type: 'input'
  },
  {
    enabled: [nc, ob],
    column: 2,
    field: 'acbPR',
    required: false,
    type: 'select'
  },
  {
    enabled: [nc, ob],
    column: 2,
    field: 'acbUR',
    required: false,
    type: 'input'
  }
]
