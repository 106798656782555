<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.enter="cancel"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-toolbar
        dark
        flat
        :color="options.color"
      >
        <v-icon v-if="options.icon">{{ options.icon }}</v-icon>
        <v-toolbar-title class="white--text px-3">
          {{ title }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text
        v-show="!!message"
        class="py-4 px-7"
      >
        <div
          class="text-body-1 py-4"
          v-html="message"
        />
      </v-card-text>
      <v-card-actions class="pa-6 pt-2">
        <v-spacer></v-spacer>
        <div
          :class="{
            'd-flex': true,
            'flex-row-reverse': !options.reverseActions,
            'flex-row': options.reverseActions
          }"
        >
          <v-btn
            dark
            class="mx-1"
            :color="options.acceptColor"
            :depressed="!options.reverseActions"
            :text="options.reverseActions"
            @click.native="agree"
          >
            {{ options.acceptLabel || 'Yes' }}
          </v-btn>
          <v-btn
            dark
            class="mx-1"
            :color="options.cancelColor"
            :depressed="options.reverseActions"
            :text="!options.reverseActions"
            @click.native="cancel"
          >
            {{ options.cancelLabel || 'No' }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: 'primary',
      acceptColor: 'primary darken-1',
      cancelColor: 'primary darken-1',
      width: 320,
      zIndex: 200
    }
  }),
  methods: {
    open(title, message, options) {
      this.dialog = true
      this.title = title
      this.message = message
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.resolve(true)
      this.dialog = false
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>
